.header {
  position: relative;
  padding: 0 8px;
  .mobileHeaderTitle {
    margin-inline-start: -24px;
    font-size: 18px;
  }
}

@media screen and (min-width: 1024px) {
  .logoWrapper {
    height: 80px;
  }
}
